<template>
    <div class="login-container">
        <img src="@/assets/images/logo.png" class="logo" />
        <div class="phone">{{ phone }}</div>
        <div class="welcome">您好，欢迎来到洛客设计</div>
        <!-- 输入验证码或者密码的区域 -->
        <div class="login-content" :class="loginTypeClass">
            <div class="login-scroll-wrapper">
                <div class="login-scroll-item">
                    <div class="captcha-box">
                        <span class="label">验证码登录</span>
                        <input type="number" v-model="captcha" />
                    </div>

                    <van-button
                        class="captcha-button"
                        size="small"
                        :disabled="!canGetCaptcha"
                        @click="handleGetCaptcha"
                    >
                        {{ captchaBtnText }}
                    </van-button>
                </div>
                <div class="login-scroll-item">
                    <span class="label">密码登录</span>
                    <input type="password" class="password-input" v-model="password" />
                </div>
            </div>
            <div class="login-change-type">
                <span v-show="loginType === 'password'" @click="handleChangeLoginType('captcha')">
                    验证码登录
                </span>
                <span v-show="loginType === 'captcha'" @click="handleChangeLoginType('password')">
                    密码登录
                </span>
            </div>
        </div>
        <van-button type="primary" class="login-button" @click="handleLogin">登录</van-button>
    </div>
</template>

<script>
import { postSendCaptcha } from '@/api/auth';

export default {
    name: 'login',
    computed: {
        phone() {
            return this.$route.query.phone;
        },
        // 是否可获取验证码
        canGetCaptcha() {
            return this.canGetCaptchaSeconds === 0;
        },

        // 验证码按钮文本显示内容
        captchaBtnText() {
            if (this.canGetCaptchaSeconds === 0) return '获取验证码';

            return `${this.canGetCaptchaSeconds}秒后再次获取`;
        },
        // 输入凭证区域样式
        loginTypeClass() {
            return `login-${this.loginType}`;
        },
    },
    data() {
        return {
            interval: 60, // 获取验证码的间隔时间，单位秒
            prevTimestamp: 0, // 上一次获取验证码的时间戳
            canGetCaptchaSeconds: 0, // 可再次获取验证码剩余时间，单位秒

            timer: null, // 更新

            loginType: 'captcha', // captcha | password

            captcha: '', // 验证码
            password: '', // 密码
            loginToken: '', // 登录 token，获取验证码时返回
        };
    },
    methods: {
        // 获取验证码
        handleGetCaptcha() {
            // 手机号非法，则提示
            if (!this.phone) {
                return this.$notify({ type: 'warning', message: '手机号格式错误' });
            }
            postSendCaptcha(this.phone).then(([response, error]) => {
                if (!error) {
                    this.loginToken = response.data.token;
                    localStorage.setItem('loginToken', this.loginToken);
                    // 设置剩上一次请求验证码的时间
                    this.prevTimestamp = Date.now();
                    // 启动定时器
                    this.timer = setInterval(() => {
                        // 下次获取验证码剩余时间计算
                        const time = ~~(
                            (this.prevTimestamp + this.interval * 1000 - Date.now()) /
                            1000
                        );

                        if (time > 0) {
                            this.canGetCaptchaSeconds = time;
                        } else {
                            this.canGetCaptchaSeconds = 0;
                            clearInterval(this.timer);
                        }
                    }, 100);
                } else {
                    this.$toast(error.msg);
                }
            });
        },

        // 登录
        async handleLogin() {
            // 验证码登录
            if (this.loginType === 'captcha') {
                if (!this.captcha) {
                    return this.$notify({ type: 'warning', message: '请输入验证码' });
                }
                // 如果验证验非本机获取，token则获取不到，会提示填写token
                const data = {
                    phone: this.phone,
                    code: this.captcha,
                    token: this.loginToken || localStorage.getItem('loginToken'),
                };
                this.$store.dispatch('LoginByCaptcha', data).then(() => {
                    location.href = decodeURIComponent(this.$route.query.from);
                });
            }

            // 密码登录
            if (this.loginType === 'password') {
                if (!this.password) {
                    return this.$notify({ type: 'warning', message: '请输入密码' });
                }
                const data = {
                    phone: this.phone,
                    password: this.password,
                };
                this.$store.dispatch('LoginByPassword', data).then(() => {
                    location.href = decodeURIComponent(this.$route.query.from);
                });
            }
        },
        // 切换登录方式
        handleChangeLoginType(type) {
            this.loginType = type;
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style lang="scss" scoped>
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 0 2rem 5rem;
    background-color: #fff;

    .logo {
        width: 2rem;
        height: 2rem;
    }

    .phone {
        font-size: 1.2rem;
        line-height: 1.5;
        margin-top: 0.5rem;
        letter-spacing: 0.02rem;
    }

    .welcome {
        color: #868686;
        font-size: 0.7rem;
        line-height: 1.5;
        letter-spacing: 0.08rem;
        margin-top: 0.3rem;
    }

    .login-content {
        margin-top: 2.5rem;
        width: 100%;
        overflow: hidden;
        .login-scroll-wrapper {
            width: 200%;
            display: flex;
            border-bottom: 1px solid #979797;
            transition: transform 0.2s ease-in-out;
        }
        .login-scroll-item {
            display: flex;
            flex-direction: row;
            width: 50%;
            align-items: center;
            height: 2rem;
            line-height: 2rem;
            font-size: 0.75rem;
            color: #747474;

            .captcha-box {
                flex: 1;
            }
            .label {
                width: 4.1rem;
            }
            input {
                width: 5rem;
                padding: 0 1rem;
                border: none;
                outline: none;
                height: 100%;
                line-height: 2rem;
                background-color: transparent;
            }
            .password-input {
                width: 11rem;
            }
            .captcha-button {
                width: 6.5rem;
            }
        }
        &.login-captcha .login-scroll-wrapper {
            transform: translateX(0);
        }
        &.login-password .login-scroll-wrapper {
            transform: translateX(-50%);
        }

        .login-change-type {
            margin-top: 0.5rem;
            text-align: right;
            color: #747474;
            line-height: 2rem;
            height: 2rem;
            span {
                display: inline-block;
                padding: 0 0.1rem 0 0.5rem;
            }
        }
    }
    .login-button {
        margin-top: 1.5rem;
        width: 100%;
    }
}
</style>
